'use client'
import { type FC, useMemo } from 'react'
import styled from 'styled-components'
import { useDeviceType, useMount } from '../hooks'
import { designGrid } from './'

const SusyGrid = styled.div`
  ${designGrid({})}
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 9999;
`

type typeColumnProps = {
  readonly $columnIndex: number
}

const Column = styled.span<typeColumnProps>`
  background-color: rgba(76, 0, 255, 0.01);
  border: 1px solid rgba(85, 0, 255, 0.08);
  grid-area: ${({ $columnIndex }) => `${1}/${$columnIndex + 1}/${2}/${$columnIndex + 1}`};
`

const getColumns = (isMobile = undefined) => {
  const columns = isMobile ? 6 : 12
  const array = []
  for (let idx = 0; idx < columns; idx++) {
    array.push(<Column key={idx} $columnIndex={idx} />)
  }
  return array
}

export const Susy: FC = () => {
  const { isMobile } = useDeviceType()
  const isMount = useMount()
  const Columns = useMemo(() => getColumns(isMobile), [isMobile])

  return (
    <SusyGrid className='susy'>
      {isMount && Columns}
    </SusyGrid>
  )
}
