import { css } from 'styled-components'
import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value: number, factor = null) => `${value / (factor ?? rem.baseMobile)}rem`
}

export const font10_12 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(10, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
`

export const getP50 = () => {
  return css`
    font-size: ${rem.get(30, rem.baseMobile)};
    font-weight: regular;
    line-height: ${rem.get(40, rem.baseMobile)};
    letter-spacing: -0.6px;
    font-family: var(--font-domaine-display), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(50, rem.base)};
      line-height: ${rem.get(65, rem.base)};
      letter-spacing: -1px;
    }
  `
}

export const getP40 = () => {
  return css`
    font-size: ${rem.get(40, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(46.92, rem.baseMobile)};
    letter-spacing: 0px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(40, rem.base)};
      line-height: ${rem.get(46.92, rem.base)};
    }
  `
}

export const getP30 = () => {
  return css`
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(24, rem.baseMobile)};
    letter-spacing: -.2px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(30, rem.base)};
      line-height: ${rem.get(42, rem.base)};
      letter-spacing: -.3px;
    }
  `
}


export const getP20 = () => {
  return css`
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 400;
    line-height: ${rem.get(26.2, rem.baseMobile)};
    letter-spacing: 0;
    font-family: var(--font-domaine-display), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26.2, rem.base)};
      letter-spacing: 0;
    }
  `
}

export const getP20_1 = () => {
  return css`
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 400;
    line-height: ${rem.get(24, rem.baseMobile)};
    letter-spacing: 0;
    font-family: var(--font-domaine-display), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(24, rem.base)};
      letter-spacing: 0;
    }
  `
}


export const getP18_1 = () => {
  return css`
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: -.3px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: -.36px;
    }
  `
}

export const getP18_1B = () => {
  return css`
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 500;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: -0.3px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      font-weight: 700;
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: 0px;
    }
  `
}

export const getP16 = () => {
  return css`
    font-size: ${rem.get(16, rem.baseMobile)};
    font-weight: 400;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: 0;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0;
    }
  `
}
export const getP16_1b = () => {
  return css`
    font-size: ${rem.get(16, rem.baseMobile)};
    font-weight: 600;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: 0;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0;
    }
  `
}


export const getP15 = () => {
  return css`
    font-size: ${rem.get(12, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: .13px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: .17px;
    }
  `
}

export const getP15_1 = () => {
  return css`
    font-size: ${rem.get(12, rem.baseMobile)};
    font-weight: light;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: -.4px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -.5px;
    }
  `
}

export const getP15_1B = () => {
  return css`
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: -.4px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -.5px;
    }
  `
}



export const getP15_2 = () => {
  return css`
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 600;
    line-height: ${rem.get(14, rem.baseMobile)};
    letter-spacing: -.38px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(14, rem.base)};
    }
  `
}

export const getP12 = () => { // new
  return css`
    font-size: ${rem.get(9, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: 0.1px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.13px;
    }
  `
}

export const getP12_1 = () => {
  return css`
    font-size: ${rem.get(10, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(13, rem.baseMobile)};
    letter-spacing: -0.33px;
    font-family: var(--font-work-sans), sans-serif;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(14, rem.base)};
      letter-spacing: -0.3px;
    }
  `
}

export const getP20v2 = css`
  font-size: ${rem.get(20, rem.base)};
  line-height: ${rem.get(24, rem.base)};
  letter-spacing: .5px;

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(20, rem.base)};
    line-height: ${rem.get(24, rem.base)};
    letter-spacing: .5px;
  }
`
